import React from 'react'
import {AiOutlineSearch} from "react-icons/ai"
import {Button} from "reactstrap"
import TextField from '../../core/components/TextField'
import {Form} from 'reactstrap'
import {useForm} from "react-hook-form"
import SearchTextField from './SearchTextField'
import PlaceField from './PlaceField'
import TypeField from './TypeField'
import SportField from './SportField'
import FacilityTypeField from './FacilityTypeField'
import DateField from './DateField'
import SubmitFormButton from '../../core/buttons/SubmitFormButton'
import FormWrapper from '../../core/form/FormWrapper'
import {useNavigate, useRouteMatch, useLocation, useMatch} from 'react-router-dom'
import {composeUtils} from "../../core/utils/appUtil";
import * as QueryString from "query-string";
import {useSelector, useDispatch} from "react-redux";
import {loadSports} from "../../actions/app";
import moment from 'moment';

let SearchForm = ({}) => {

    let location = useLocation();

    let dispatch = useDispatch()

    let {
        distance
    } = useSelector(state => state.search)

    let form = useForm({
        defaultValues: QueryString.parse(location.search)
    })
    let watch = form.watch

    let searchType = form.watch('type') ?? 'rental'

    let navigate = useNavigate()

    React.useEffect(() => {
        dispatch(loadSports())
    }, [])

    let submit = (data) => {
        const searchParams = new URLSearchParams();
        if (data.place) {
            searchParams.append("place", data.place);
        }
        if (data.type) {
            searchParams.append("type", data.type);
        }
        if (data.type === 'training' && data.sport) {
            searchParams.append("sport", data.sport);
        }
        if (data.type === 'rental' && data.facilityType) {
            searchParams.append("facilityType", data.facilityType);
        }
        if (!data.day) {
            searchParams.append("day", moment().format('yyyy-MM-DD'));
        }
        if (!data.hour) {
            searchParams.append("hour", moment().hour());
        }
        navigate('/szukaj?' + searchParams.toString())
    }

    return <Form onSubmit={form.handleSubmit(submit)} className='search'>
        <div className='inputs d-flex'>
            {/*<SearchTextField form={form}/>*/}
            <PlaceField form={form}/>
            <TypeField form={form}/>
            {searchType === 'training' && <SportField form={form}/>}
            {searchType === 'rental' && <FacilityTypeField form={form}/>}
            {/*<DateField form={form}/>*/}
            <FormWrapper>
                <SubmitFormButton disabled={false} text='Szukaj'/>
            </FormWrapper>
        </div>
    </Form>

}

export default composeUtils(SearchForm)