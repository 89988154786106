import {
    CHANGE_PLACE_TEXT,
    PLACES_AUTOCOMPLETE_REQUEST,
    PLACES_AUTOCOMPLETE_SUCCESS, SEARCH_REQUEST, SEARCH_SUCCESS
} from "../constants/actiontypes/facility";
import {createAction} from "../utils/reduxUtil";
import {getPlacesAutocomplete} from "../api/app";
import {toastError} from "../utils/toastUtil";
import handleError from "../utils/errorUtil";
import * as api from "../api/app";
import schemas from "../reducers/schemas";
import {normalize} from "normalizr";


export function autocompletePlaces(text) {
    return function (dispatch) {
        dispatch(createAction(CHANGE_PLACE_TEXT, text));
        if (text.length > 3) {
            dispatch(createAction(PLACES_AUTOCOMPLETE_REQUEST));
            getPlacesAutocomplete(text).then(response => {
                dispatch(createAction(PLACES_AUTOCOMPLETE_SUCCESS, response.data));
            }).catch(err => handleError(err, dispatch))
        }
    }
}

export function search(params) {
    let type = params.type
    return function (dispatch) {
        dispatch(createAction(SEARCH_REQUEST, params));
        if (type === 'rental') {
            dispatch(searchFacilities(params.day, params.hour, params.place, params.distance, params.facilityType, params.customFieldOptions))
        } else {
            dispatch(searchBusinesses(params.day, params.hour, params.place, params.distance, params.sport))
        }
    }
}

export function searchFacilities(day, hour, place, distance, facilityType, customFieldOptions) {
    return function (dispatch) {
        api.searchFacilities({
            day, hour, place, facilityType, distance, customFieldOptions: Array.isArray(customFieldOptions) ? customFieldOptions : (customFieldOptions ? [customFieldOptions] : [])
        }).then(response => {
            let normalizedData = normalize(response.data, [schemas.facilitySchema]);
            dispatch(createAction(SEARCH_SUCCESS, normalizedData));
        }).catch(err => handleError(err, dispatch));
    }
}

export function searchBusinesses(day, hour, place, distance, sport) {
    return function (dispatch) {
        api.searchBusinesses({
            day, hour, place, distance, sport
        }).then(response => {
            let normalizedData = normalize(response.data, [schemas.businessSchema]);
            dispatch(createAction(SEARCH_SUCCESS, normalizedData));
        }).catch(err => handleError(err, dispatch));
    }
}