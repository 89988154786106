import React from 'react';
import {injectIntl} from "react-intl";
import Button from '../core/buttons/Button';
import {useSelector} from "react-redux";
import {initReservationModal} from "../actions/reservation";
import {getFacilityDetails} from "../actions/facility";
import {withRouter, Link} from 'react-router-dom';
import {getDistance} from 'geolib';
import moment from 'moment';
import {createAction} from "../utils/reduxUtil";
import {CHANGE_CURRENT_OBJECT} from "../constants/actiontypes/facility";
import {useNavigate} from 'react-router-dom'
import BusinessSliderItem from './BusinessSliderItem'
import ReserveButton from '../components/buttons/ReserveButton'
import {getPriceText} from "../utils/appUtil";
import {useIntl} from 'react-intl'
import ServiceTable from './facility/ServiceTable'
import {composeUtils} from "../core/utils/appUtil";
import {MdGpsFixed} from 'react-icons/md'
import IconButton from "../core/buttons/IconButton";
import {getBusinessMainImage, getMainImage} from "../utils/facilityUtil";

let SearchResultTile = ({enable_hover, businessId, facilityId, type, intl, dispatch}) => {

    let navigate = useNavigate()

    let business = useSelector(state => state.business.byId[businessId])

    let facility = useSelector(state => state.facility.byId[facilityId])

    let currentObject = type === 'facility' ? facility : business

    let services = useSelector(state => state.service.byId)

    let businessUrl = '/biznes/' + business.subdomain

    let facilityUrl = '/biznes/' + business.subdomain + '/obiekt/' + facility?.slug

    let {
        currentLocation,
        mobile: isMobile
    } = useSelector(state => state.app)

    let openDetails = () => {
        // dispatch(getFacilityDetails(facility.slug))
        // navigate('/' + business.subdomain + '/' + facility.slug);
    }

    let setCurrentBusiness = () => {
        if (!isMobile && enable_hover) {
            dispatch(createAction(CHANGE_CURRENT_OBJECT, currentObject.id))
        }
    }

    let distance = null

    if (currentLocation) {
        distance = getDistance(
            {
                latitude: currentLocation.coords.latitude,
                longitude: currentLocation.coords.longitude
            },
            {latitude: currentObject.address.lat, longitude: currentObject.address.lon}
        );
    }

    return <div className='search-result-item'>
        <div className='d-flex flex-row my-2 p-2'>
            <div className='facility-slider-item'>
                <div className='facility-slider-item-card'>
                    <div className='mb-2 image-container'>
                        <img src={facilityId ? getMainImage(facilityId) : getBusinessMainImage(businessId)}
                             style={{maxWidth: '100%'}}
                        />
                    </div>
                    <div className='details'>
                        <Link to={businessUrl} style={{color: 'black', textDecoration: 'none'}} className='fw-bold name'>{business.name}</Link>
                        <p className='address'>{business.address.city}, {business.address.address}, {business.address.postcode}</p>
                    </div>
                </div>
            </div>
            <div className='p-2 w-100'>
                {facility && <p>{facility.name}</p>}
                {facility && <Link to={facilityUrl}>Przejdź do obiektu</Link>}
                {
                    Object.keys(business?.free_terms).map(serviceId => {
                        return <tr>
                            <td>{services[serviceId].name}</td>
                            <td>
                                {
                                    business.free_terms[serviceId].slice(0, 5).map(term =>
                                        <Button text={moment(term.time_from).format('HH:mm')} onClick={() => {}}/>
                                    )
                                }
                            </td>
                        </tr>
                    })
                }
                {/*<ServiceTable facilityId={facility.id}/>*/}
            </div>
            <MdGpsFixed className='navigate-to-object' size={20} onClick={setCurrentBusiness}/>
        </div>
    </div>
}

export default composeUtils(SearchResultTile)
