import React, {useState} from 'react'
import Loader from "./Loader";
import Navigation from "./Navigation";
import RentalType from "./RentalType";
import RentalDisabledInfo from "./RentalDisabledInfo";
import {useSelector, useDispatch} from 'react-redux'
import {RENTAL_CALENDAR_INIT, RENTAL_CALENDAR_RESET} from "../../reducers/rentalcalendar";
import {createAction} from '../../utils/reduxUtil'
import moment from 'moment'
import TermTable from "./TermTable";
import DurationSelect from "./DurationSelect";
import FacilityPartSelect from "./FacilityPartSelect";
import FacilityPart from "./FacilityPart";
import RentalModal from '../rentalmodal/RentalModal'
import {RENTAL_MODAL_SUCCESS} from "../../reducers/rentalModal";
import {useLocation} from 'react-router-dom'
import * as QueryString from "query-string";
import {useNavigate} from 'react-router-dom'

let RentalCalendar = () => {

    let navigate = useNavigate()

    let location = useLocation()

    let queryParams = QueryString.parse(location.search)

    let dispatch = useDispatch()

    let {loading, facilityId, serviceId} = useSelector(state => state.rentalcalendar)

    let {opened: modalOpened} = useSelector(state => state.rentalModal)

    let facility = useSelector(state => state.facility.byId[facilityId])

    let service = useSelector(state => state.service.byId[serviceId])

    React.useEffect(() => {
        if(queryParams.success) {
            dispatch(createAction(RENTAL_MODAL_SUCCESS));
            navigate(location.pathname)
        }
    }, [])

    return <div className='rentalcalendar'>
        {modalOpened && <RentalModal/>}
        <h3>Wybierz termin, który chcesz zarezerwować {serviceId ? ' dla usługi ' : ''}{serviceId ? <b>{service.name}</b> : null}</h3>
        <Loader/>
        <RentalDisabledInfo/>
        <div className='d-flex' style={{flexDirection: 'row', justifyContent: 'center', gap: '10px', marginTop: '30px'}}>
            <DurationSelect/>
            {['partial-rental','entire-rental'].includes(service.type)&& <RentalType/>}
            {!['partial-rental','entire-rental'].includes(service.type)&& <FacilityPart/>}
        </div>
        <Navigation facilityId={facilityId}/>
        <TermTable/>
    </div>

}

export default RentalCalendar