import React from 'react'
import {useSelector} from 'react-redux'
import {getDistance} from "geolib";
import {getPriceText} from "../../utils/appUtil";
import ReserveButton from "../buttons/ReserveButton";
import {useIntl} from 'react-intl'

let ServicesTable = ({facilityId}) => {

    let intl = useIntl()

    let facility = useSelector(state => state.facility.byId[facilityId])

    let services = useSelector(state => state.service.byId)

    let businesses = useSelector(state => state.business.byId)

    let business = businesses[facility.business]

    if (!facility?.services?.length) {
        return <div className='d-flex flex-column w-100'>
            <p>Obiekt nie dodał jeszcze żadnych usług, które mógłbyś zarezerwować :(</p>
        </div>
    }

    return <table className='w-100'>
        <tbody>
        {facility.services &&
            facility.services.map((serviceId, key) => <tr>
                    <td className='py-2'>
                        {services[serviceId].name}
                    </td>
                    <td className='py-2'>
                        <div className='d-flex flex-column text-end px-2'>
                            <div className='fw-bold'>
                                {getPriceText(services[serviceId], intl)}
                            </div>
                            <div>
                                {services[serviceId].duration} min.
                            </div>
                        </div>
                    </td>
                    {
                        business.reservations_enabled && <td className='d-flex justify-content-center'>
                            <ReserveButton facilityId={facility.id} serviceId={serviceId}/>
                        </td>
                    }
                    {
                        !business.reservations_enabled && key === 0 &&
                        <td rowSpan={facility.services?.length} style={{maxWidth: '80px', color: 'orange'}}
                            className='fw-bold text-center text-'>
                            obiekt nie udostępnił jeszcze możliwości rezerwacji usług
                        </td>
                    }
                </tr>
            )}
        </tbody>
    </table>
}

export default ServicesTable