import getApi from './api.js';

export function getAllNotifications(page = 0, perPage = 5) {
    let params = {params: {per_page: perPage, page: page, sort: '-created_at'}};
    return getApi().get('notification', params)
}

export function delNotification(notification) {
    return getApi().delete('notification/' + notification)
}

export function getAllFacilities(includes = [], filters = [], search = '', place = '', distance, sort) {
    let f = {}
    if (Object.keys(filters).length > 0) {
        for (let i = 0; i < Object.keys(filters).length; i++) {
            let key = Object.keys(filters)[i]
            if (filters[key]) {
                f['filter[' + key + ']'] = filters[key]
            }
        }
    }
    let params = {params: {include: includes.join(','), ...f, search, place, distance, sort: sort}};

    return getApi().get('facility', params);
}

export function searchFacilities(extraParams) {
    let params = {params: {include: 'business,images,type.customFields.options', ...extraParams}};

    return getApi().get('facility', params);
}

export function searchBusinesses(extraParams) {
    let params = {params: {include: 'address,images,services', ...extraParams}};

    return getApi().get('business', params);
}

export function getPlacesAutocomplete(text) {
    return getApi().get('places', {params: {search: text}});
}

export function getFacilityBySlug(slug, businessId) {
    let params = {
        params: {
            'filter[slug]': slug,
            'filter[business.uuid]': businessId,
            include: 'openingHours,images,business,services,resources,parts'
        }
    }
    return getApi().get('facility', params);
}

export function getBusinessesBySubdomain(subdomain) {
    let params = {
        params: {
            'filter[subdomain]': subdomain,
            include: 'facilities.images,services,openingHours,amenities,sports,images,address'
        }
    }
    return getApi().get('business', params);
}

export function getFacilitiesByIds(ids) {
    let params = {
        params: {
            id: ids,
            include: 'openingHours,images,business,services',
            per_page: ids.length
        }
    }
    return getApi().get('facility', params);
}

export function getFacilityById(id) {
    let params = {
        params: {
            include: 'openingHours,images,business,services'
        }
    }
    return getApi().get('facility/' + id, params);
}

export function getTerms(serviceId, priceListId, from, to) {
    let params = {
        service_id: serviceId,
        price_list_id: priceListId
    }
    if(to) {
        params.from = from
        params.to = to
    } else {
        params.date = from
    }
    return getApi().get('/service/' + serviceId + '/term', {params: params});
}

export function getFacilityTypes() {
    return getApi().get('facilityType');
}

export function getSports() {
    return getApi().get('sport');
}

export function createReservation(submitData) {
    return getApi().post('reservation', submitData);
}

export function createReservationCycle(submitData) {
    return getApi().post('reservationCycle', submitData);
}

export function getPrzelewy24PaymentMethods(price) {
    return getApi().get('przelewy24/paymentMethods?amount=' + price);
}

export function createPrzelewy24Payment(data) {
    return getApi().post('przelewy24/payment', data);
}

export function updateReservation(reservationId, data) {
    return getApi().put('reservation/' + reservationId, data);
}

export function getMe() {
    return getApi().get('account');
}

export function updateAccountData(formData) {
    return getApi().post('account', formData);
}

export function getReservations(filters, includes) {
    return getApi().get('reservation', {
        params: {
            ...filters,
            include: includes
        }
    });
}

export function updateFacility(facilityId, data) {
    return getApi().put('facility/' + facilityId, data)
}

export function geServiceTerms(serviceId, from, to) {
    let params = {
        type: 'range',
        date_from: from,
        date_to: to
    }
    return getApi().get('/service/' + serviceId + '/term', {params: params});
}